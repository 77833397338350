
import { Badge } from "@/entities/badge";
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["click"],
  props: {
    badge: Object,
    step: Number
  }
})
export default class MBadgeCard extends Vue {
  badge!: Badge;
  step = -2;

  get image(): string {
    if (this.step === -2) {
      return this.badge.data.blankImage;
    } else if (this.step === -1) {
      return this.badge.data.image;
    } else {
      return this.badge.data.stepsBadgeImage[this.step];
    }
  }

  get title(): string {
    if (this.step === -2 || this.step === -1) {
      return "";
    }
    return this.badge.data.stepsTitle[this.step];
  }

  get titleClass(): string {
    if (this.step === -2 || this.step === -1) {
      return "";
    }
    const color = this.badge.data.stepsBadgeColors[this.step];
    if (color === "gold") {
      return "text-primary-500";
    }
    return `text-${color}`;
  }

  get description(): string {
    if (this.step === -2) {
      return this.badge.data.placeholder;
    } else if (this.step === -1) {
      return this.badge.data.description;
    } else {
      return this.badge.data.stepsDescription[this.step];
    }
  }

  click() {
    this.$emit("click");
  }
}
