
import { Options, Vue } from "vue-class-component";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import { SubmissionStatus } from "@/entities/submission";
import {
  convertToDateFromUnixtimeSlash,
  convertToTimeFromUnixtime
} from "@/utils/date";

@Options({
  components: {
    MIcon,
    MButton
  },
  emits: ["click", "confirm"],
  props: {
    title: String,
    label: String,
    closedAt: Number,
    checkedAt: Number,
    submittableFrom: Number,
    displayEndAt: Number,
    status: String,
    submissionMasterId: String,
    submissionId: String
  }
})
export default class SubmissionCard extends Vue {
  title = "";
  label = "";
  closedAt = 0;
  checkedAt = 0;
  submittableFrom = 0;
  status: SubmissionStatus = "unsubmitted";
  submissionMasterId = "";
  submissionId = "";

  toTimeString(unix: number): string {
    return `${convertToDateFromUnixtimeSlash(unix)} ${convertToTimeFromUnixtime(
      unix
    )}`;
  }

  toDateString(unix: number): string {
    return `${convertToDateFromUnixtimeSlash(unix)}`;
  }

  click() {
    const url = `/submission/print?id=${this.submissionId}`;
    window.open(url, "_blank");
  }

  confirm() {
    this.$emit("confirm");
  }
}
