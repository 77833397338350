import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-16 flex-none flex flex-col items-center" }
const _hoisted_2 = { class: "ml-6 flex-1 flex flex-col" }
const _hoisted_3 = { class: "mb-1 text-sm font-bold" }
const _hoisted_4 = { class: "text-xs text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "w-full p-3 flex flex-shrink-0 items-center rounded-md bg-white border-2 border-gray-200 cursor-pointer",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("img", {
        src: _ctx.image,
        alt: "バッジ画像",
        class: "w-full"
      }, null, 8, ["src"]),
      (_ctx.title)
        ? (_openBlock(), _createBlock("p", {
            key: 0,
            class: ["mt-1 text-xs font-medium", _ctx.titleClass]
          }, _toDisplayString(_ctx.title), 3))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode("p", _hoisted_3, _toDisplayString(_ctx.badge.data.name), 1),
      _createVNode("p", _hoisted_4, _toDisplayString(_ctx.description), 1)
    ])
  ]))
}