
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import MButton from "@/components/MButton.vue";
import MSPanelHeader from "@/components/student/MSPanelHeader.vue";
import { Student } from "@/entities/student";
import store from "@/store";
import { Getter } from "@/store/helper";
import { getSchoolIdOfStudent } from "@/api/school";
import { unitCollectionKey, Unit, convertToUnit } from "@/entities/reflection";

@Options({
  components: {
    MSPanelHeader,
    MIcon,
    MButton
  },
  emits: ["open", "select", "openReflectionAddModal"],
  props: {
    student: Object
  }
})
export default class ReflectionPanel extends Vue {
  student!: Student;
  units: Unit[] = [];
  loading = false;

  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isAdminOfSchool") isAdminOfSchool!: boolean;
  @Getter("isSupervisorOfSchool") isSupervisorOfSchool!: boolean;
  @Getter("isEmployeeOfSchool") isEmployeeOfSchool!: boolean;
  @Getter("isTutorOfSchool") isTutorOfSchool!: boolean;

  get canEditStudent(): boolean {
    const currentSchoolId = store.state.school?.ref.id;
    const schoolIdOfThisStudent = getSchoolIdOfStudent(this.student);

    if (!currentSchoolId || !schoolIdOfThisStudent) {
      return false;
    }

    return currentSchoolId === schoolIdOfThisStudent;
  }

  open() {
    this.$emit("open");
  }

  openReflectionAddModal() {
    this.$emit("openReflectionAddModal");
  }

  select(reflection: { type: "edit" | "display"; value: Unit }) {
    this.$emit("select", reflection);
  }

  async created(): Promise<void> {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      return;
    }
    try {
      store.commit("START_LOADING", "情報取得中...");
      this.loading = true;
      const snapshot = await this.student.ref
        .collection(unitCollectionKey)
        .get();
      this.units = snapshot.empty
        ? []
        : snapshot.docs
            .filter(doc => doc.exists && doc.data())
            .map(doc => convertToUnit(doc.data(), doc.ref))
            .sort((a, b) => {
              if (a.data.createdAt === b.data.createdAt) {
                return a.data.name.localeCompare(b.data.name);
              }
              return b.data.createdAt - a.data.createdAt;
            });
    } catch (e) {
      alert("振り返りの取得に失敗しました。");
      console.error(e);
    } finally {
      store.commit("END_LOADING");
      this.loading = false;
    }
  }
}
