
import { Options, Vue } from "vue-class-component";
import { ThreadPost } from "@/entities/thread";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import {
  convertToDateFromUnixtimeSlash,
  convertToTimeFromUnixtime
} from "@/utils/date";

@Options({
  components: {
    MIcon,
    MButton
  },
  emits: ["click", "confirm"],
  props: {
    title: String,
    label: String,
    posts: Array,
    submittableFrom: Number,
    displayEndAt: Number,
    threadMasterId: String,
    threadId: String
  }
})
export default class SubmissionCard extends Vue {
  title = "";
  label = "";
  posts = [] as ThreadPost[];
  submittableFrom = 0;
  threadMasterId = "";
  threadId = "";

  get latestPostTime() {
    return this.posts.sort((a, b) => b.time - a.time)[0]?.time ?? 0;
  }

  toTimeString(unix: number): string {
    return `${convertToDateFromUnixtimeSlash(unix)} ${convertToTimeFromUnixtime(
      unix
    )}`;
  }

  toDateString(unix: number): string {
    return `${convertToDateFromUnixtimeSlash(unix)}`;
  }

  click() {
    const url = `/thread/print?id=${this.threadId}`;
    window.open(url, "_blank");
  }

  confirm() {
    this.$emit("confirm");
  }
}
