
import { fetchHasBadges } from "@/api/badge";
import MBadgeCard from "@/components/badge/MBadgeCard.vue";
import MBadgeModal from "@/components/badge/MBadgeModal.vue";
import MSPanelHeader from "@/components/student/MSPanelHeader.vue";
import { Badge, HasBadge } from "@/entities/badge";
import { Student } from "@/entities/student";
import { State } from "@/store/helper";
import { Options, Vue } from "vue-class-component";

type BadgeCardData = {
  badge: Badge;
  step: number;
};

@Options({
  components: {
    MBadgeCard,
    MBadgeModal,
    MSPanelHeader
  },
  emits: ["open"],
  props: {
    student: Object
  }
})
export default class MSBadgePanel extends Vue {
  selectedBadgeId = "";
  student!: Student;
  hasBadges: HasBadge[] = [];

  @State("badges", "student") badges!: Badge[];

  get badgeCardDatas(): BadgeCardData[] {
    const datas = this.badges.map(badge => {
      const matchHasBadges = this.hasBadges.filter(
        item => item.data.badge.id === badge.ref.id
      );
      if (matchHasBadges.length === 0) {
        const data: BadgeCardData = {
          badge,
          step: -2
        };
        return data;
      } else {
        const data: BadgeCardData = {
          badge,
          step: matchHasBadges[0].data.stepIndex
        };
        return data;
      }
    });
    return datas;
  }

  get selectedBadgeData(): BadgeCardData | null {
    if (!this.selectedBadgeId) {
      return null;
    }
    const matchDatas = this.badgeCardDatas.filter(
      data => data.badge.ref.id === this.selectedBadgeId
    );
    if (matchDatas.length === 0) {
      return null;
    }
    return matchDatas[0];
  }

  setSelectedBadgeId(badgeId: string) {
    this.selectedBadgeId = badgeId;
  }

  open() {
    this.$emit("open");
  }

  async created() {
    const hasBadges = await fetchHasBadges(this.student.ref);
    this.hasBadges = hasBadges;
    document.title = `${this.student.data.name} バッジ一覧`;
  }
}
