
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MSQandaRegisterModal from "@/components/student/MSQandaRegisterModal.vue";
import { Student } from "@/entities/student";
import store from "@/store";
import "firebase/firestore";
import "firebase/functions";
import { todoCollectionKey } from "../../entities/todo";

type TodoData = {
  id: string;
  title: string;
  createdAt: string;
  checkedAt: string | null;
};

@Options({
  components: {
    MIcon,
    MBaseModal,
    MButton,
    MSelectBox,
    MTextField,
    MTextArea,
    MSQandaRegisterModal
  },
  emits: ["close"],
  props: {
    student: Object,
    todo: Object
  }
})
export default class MsEditModal extends Vue {
  student!: Student;
  todo: TodoData | null = null;
  title = "";

  get validData() {
    return this.title.length > 0;
  }

  close() {
    this.$emit("close");
  }

  async updateTodo() {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      return;
    }
    if (!this.title) {
      alert("TODOのタイトルを入力してください。");
      return;
    }

    try {
      store.commit("START_LOADING", "TODO更新中...");
      await this.student.ref
        .collection(todoCollectionKey)
        .doc(this.todo?.id)
        .update({ title: this.title });
    } catch (e) {
      alert("TODO情報の更新に失敗しました。");
      console.error(e);
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  async deleteTodo() {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      return;
    }
    if (!this.title) {
      alert("TODOのタイトルを入力してください。");
      return;
    }

    if (!window.confirm("このTODOを削除してもよろしいですか？")) return;

    try {
      store.commit("START_LOADING", "TODO削除中...");
      await this.student.ref
        .collection(todoCollectionKey)
        .doc(this.todo?.id)
        .delete();
      alert("TODOを削除しました。");
    } catch (e) {
      alert("TODOの削除に失敗しました。");
      console.error(e);
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  created(): void {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      this.$emit("close");
    }
    if (!this.todo) {
      alert("TODO情報が取得できません。");
      this.$emit("close");
    }
    this.title = (this.todo as TodoData).title;
  }
}
