import firebase from "firebase/app";

export const externalServicesCollectionKey = "externalServices";

export type ExternalService = {
  ref: firebase.firestore.DocumentReference;
  data: ExternalServiceData;
};

export type ExternalServiceData = {
  createdAt: string;
  description: string;
  isNandeUserCreated: boolean;
  nandeUserId: string;
  isLinkedToQanda: boolean;
  qandaUserId: string;
  serviceDomain: string;
  unlinkSchoolAI?: boolean;
  unlinkSchoolAIAt?: number;
  relinkSchoolAIAt?: number;
};

export function convertToExternalService(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): ExternalService {
  const returnData: ExternalServiceData = {
    createdAt: data.createdAt,
    description: data.description,
    isNandeUserCreated: data.isNandeUserCreated ?? false,
    nandeUserId: data.nandeUserId ?? "",
    isLinkedToQanda: data.isLinkedToQanda ?? false,
    qandaUserId: data.qandaUserId ?? "",
    serviceDomain: data.serviceDomain
  };
  if ("unlinkSchoolAI" in data) {
    returnData.unlinkSchoolAI = data.unlinkSchoolAI;
    if (data.unlinkSchoolAIAt) {
      returnData.unlinkSchoolAIAt = data.unlinkSchoolAIAt;
    }
    if (data.relinkSchoolAIAt) {
      returnData.relinkSchoolAIAt = data.relinkSchoolAIAt;
    }
  }
  return {
    ref,
    data: returnData
  };
}
