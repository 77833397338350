
import { Options, Vue } from "vue-class-component";

export type LearningSummaryData = {
  num: number;
  label: string;
};

@Options({
  props: {
    title: String,
    values: Array
  }
})
export default class MLearningSummaryCard extends Vue {
  title!: string;
  values: LearningSummaryData[] = [];
}
