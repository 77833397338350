
import MIcon from "@/components/MIcon.vue";
import MButton from "@/components/MButton.vue";
import MsEditLearningModal from "@/components/student/MsEditLearningModal.vue";
import { Learning, LearningRecord } from "@/entities/learning";
import { State } from "@/store/helper";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MIcon,
    MsEditLearningModal,
    MButton
  },
  emits: ["open"],
  props: {
    student: Object
  }
})
export default class MSLearningPanel extends Vue {
  isDisplayEditLearningModal = false;
  @State("learning", "student") learning!: Learning | null;

  get existReflection(): boolean {
    if (!this.learning || !this.learning.data.reflection) {
      return false;
    } else {
      return true;
    }
  }

  get date(): string {
    if (!this.learning) {
      return "";
    }
    const timeRecords = [...this.learning.data.records];
    timeRecords.sort((a, b) => a.end - b.end);
    const startTime = dayjs(timeRecords[0].start * 1000).locale("ja");
    return startTime.format("M/D");
  }

  get sortedRecords(): LearningRecord[] {
    if (!this.learning) {
      return [];
    }
    const timeRecords = [...this.learning.data.records];
    timeRecords.sort((a, b) => a.end - b.end);
    return timeRecords;
  }

  get learningTimeText(): string {
    if (this.sortedRecords.length === 0) {
      return "0時間0分";
    }
    const learningSeconds = this.sortedRecords.reduce(
      (pre, current) => pre + current.end - current.start,
      0
    );
    const timeMinute = Math.floor(learningSeconds / 60);
    return `${Math.floor(timeMinute / 60)}時間${timeMinute % 60}分`;
  }

  get subjects(): string {
    if (!this.learning) {
      return "-";
    }
    const labels = this.learning.data.subjects
      .map(subject => subject.name)
      .join(" : ");
    const ratio = this.learning.data.subjects
      .map(subject => subject.ratio)
      .join(" : ");
    return `${labels} = ${ratio}`;
  }

  get goal(): string {
    if (!this.learning) {
      return "";
    }
    return this.learning.data.goal;
  }

  get goalAchievement(): number {
    if (!this.learning || !this.learning.data.reflection) {
      return 0;
    }
    return this.learning.data.reflection.achievementRate;
  }

  get achievementCss() {
    if (!this.learning || !this.learning.data.reflection) {
      return { "w-0": true };
    }
    const achievement = this.learning.data.reflection.achievementRate;
    if (achievement <= 0) {
      return { "w-0": true };
    } else if (achievement <= 8) {
      return { "w-1/12": true };
    } else if (achievement <= 17) {
      return { "w-1/6": true };
    } else if (achievement <= 25) {
      return { "w-1/4": true };
    } else if (achievement <= 33) {
      return { "w-1/3": true };
    } else if (achievement <= 42) {
      return { "w-5/12": true };
    } else if (achievement <= 50) {
      return { "w-1/2": true };
    } else if (achievement <= 58) {
      return { "w-7/12": true };
    } else if (achievement <= 67) {
      return { "w-2/3": true };
    } else if (achievement <= 75) {
      return { "w-3/4": true };
    } else if (achievement <= 83) {
      return { "w-5/6": true };
    } else if (achievement <= 99) {
      return { "w-11/12": true };
    } else {
      return { "w-full": true };
    }
  }

  get additionalContent(): string {
    if (
      !this.learning ||
      !this.learning.data.reflection ||
      this.learning.data.reflection.additionalContent.length === 0
    ) {
      return "-";
    }
    return this.learning.data.reflection.additionalContent;
  }

  get images(): string[] {
    if (!this.learning || !this.learning.data.reflection) {
      return [];
    }
    return this.learning.data.reflection.images;
  }

  get thoughts(): string {
    if (
      !this.learning ||
      !this.learning.data.reflection ||
      this.learning.data.reflection.thoughts.length === 0
    ) {
      return "-";
    }
    return this.learning.data.reflection.thoughts;
  }

  get other(): string {
    if (
      !this.learning ||
      !this.learning.data.reflection ||
      this.learning.data.reflection.other.length === 0
    ) {
      return "-";
    }

    return this.learning.data.reflection.other;
  }

  timeTextOf(record: LearningRecord): string {
    const startTime = dayjs(record.start * 1000).locale("ja");
    const endTime = dayjs(record.end * 1000).locale("ja");
    return startTime.format("M/D HH:mm") + " 〜 " + endTime.format("HH:mm");
  }

  open() {
    this.$emit("open");
  }
}
