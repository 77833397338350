
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import { Student } from "@/entities/student";
import store from "@/store";
import "firebase/firestore";
import "firebase/functions";
import { Unit } from "@/entities/reflection";

@Options({
  components: {
    MIcon,
    MBaseModal,
    MButton,
    MTextField
  },
  emits: ["close"],
  props: {
    student: Object,
    unit: Object
  }
})
export default class MsEditModal extends Vue {
  student!: Student;
  unit: Unit | null = null;
  title = "";

  get validData() {
    return this.title.length > 0;
  }

  close() {
    this.$emit("close");
  }

  async updateUnit() {
    if (!this.unit) return;
    if (!this.student) {
      alert("生徒情報が取得できません。");
      return;
    }
    if (!this.title) {
      alert("単元名を入力してください。");
      return;
    }

    try {
      store.commit("START_LOADING", "単元更新中...");
      await this.unit.ref.update({ name: this.title });
    } catch (e) {
      alert("単元情報の更新に失敗しました。");
      console.error(e);
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  async deleteUnit() {
    if (!this.unit) return;
    if (!this.student) {
      alert("生徒情報が取得できません。");
      return;
    }
    if (!this.title) {
      alert("単元名を入力してください。");
      return;
    }
    if (!window.confirm("この単元を削除してもよろしいですか？")) return;

    try {
      store.commit("START_LOADING", "削除中...");
      await this.unit.ref.delete();
      alert("単元を削除しました。");
    } catch (e) {
      alert("単元の削除に失敗しました。");
      console.error(e);
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  created(): void {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      this.$emit("close");
    }
    if (!this.unit) {
      alert("単元情報が取得できません。");
      this.$emit("close");
    }
    this.title = (this.unit as Unit).data.name;
  }
}
