import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center w-full p-3 bg-white rounded-md border-2 border-gray-200" }
const _hoisted_2 = { class: "font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_icon = _resolveComponent("m-icon")

  return (_openBlock(), _createBlock("article", _hoisted_1, [
    _createVNode("p", _hoisted_2, _toDisplayString(_ctx.text), 1),
    (_ctx.deletable)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: "flex-0 text-red-500",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.deleteData && _ctx.deleteData(...args)))
        }, [
          _createVNode(_component_m_icon, { type: "trash" })
        ]))
      : _createCommentVNode("", true)
  ]))
}