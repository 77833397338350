import firebase from "firebase/app";

export const todoCollectionKey = "todos";

export type Todo = {
  ref: firebase.firestore.DocumentReference;
  data: TodoData;
};

export type TodoData = {
  title: string;
  createdAt: number;
  isDone: boolean;
  isDoneAt?: number;
  reflection?: "bad" | "good" | "best" | null;
};

export function convertToTodo(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Todo {
  return {
    ref,
    data: {
      title: data.title,
      createdAt: data.createdAt,
      isDone: data.isDone,
      isDoneAt: data.isDoneAt ?? 0,
      reflection: data.reflection ?? null
    }
  };
}
