
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import { getStudentSecretRefFromStudentRef, Student } from "@/entities/student";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { createOrUpdateStudentScret } from "@/api/student";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MBaseModal,
    MButton,
    MTextArea
  },
  emits: ["close"],
  props: {
    student: Object,
    comment: String
  }
})
export default class MsCommentModal extends Vue {
  student!: Student;
  comment = "";
  newComment = "";

  get validComment() {
    return this.newComment.length > 0;
  }

  get validData() {
    return this.validComment;
  }

  async update() {
    if (
      !this.validData ||
      !store.state.role ||
      store.state.role.data.type !== "admin" ||
      !store.state.admin
    ) {
      return;
    }

    store.commit("START_LOADING", "更新中...");

    const studentSecretRef = getStudentSecretRefFromStudentRef(
      this.student.ref
    );
    try {
      await createOrUpdateStudentScret(studentSecretRef, {
        comment: this.newComment
      });
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`管理人メモの更新に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update student secret"
      );
      return;
    }

    this.$router.go(0);
  }

  close() {
    this.$emit("close");
  }

  created() {
    this.newComment = this.comment;
  }
}
