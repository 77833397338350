
import { Options, Vue } from "vue-class-component";
import MButton from "../MButton.vue";
import MBaseModal from "@/components/MBaseModal.vue";
import MTextField from "../form/MTextField.vue";
import store from "@/store";
import { Student } from "@/entities/student";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { externalServicesCollectionKey } from "../../entities/external_service";
import { validateQandaAPIResponse } from "@/api/qanda";

@Options({
  components: { MBaseModal, MButton, MTextField },
  emits: ["qandaRegisterClose"],
  props: { student: Object }
})
export default class MsQandaRegisterModal extends Vue {
  student!: Student;
  modalSize = "flat";
  isRegistrationDone = false;
  qandaId = "";

  get validData() {
    return this.qandaId.length > 0;
  }

  normalizeString(str: string): string {
    const removedLeadingHash = str.replace(/^[#＃]+/, "");
    const convertedToHalfWidth = removedLeadingHash.replace(
      /[Ａ-Ｚａ-ｚ０-９]/g,
      char => {
        return String.fromCharCode(char.charCodeAt(0) - 0xfee0);
      }
    );

    return convertedToHalfWidth.trim();
  }

  async registerStudentForQanda() {
    if (this.student.data.isLinkedToQanda === true) {
      alert("この生徒はすでにQandaに連携済みです");
      return;
    }
    if (this.student.data.recessTime && this.student.data.recessTime > 0) {
      alert("休会中の生徒はQandaに連携できません。");
      return;
    }
    try {
      store.commit("START_LOADING", "変更保存中...");

      const linkStudent = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("link_student_to_qanda_account");
      this.qandaId = this.normalizeString(this.qandaId);
      const res = await linkStudent({ qandaId: this.qandaId });

      validateQandaAPIResponse(res);

      // 念のためバリデーション
      if (
        res.data.res.qandaUniqueId !== this.qandaId ||
        res.data.res.registered === false
      ) {
        throw new Error("予期せぬエラーが発生しました。");
      }

      const db = firebase.firestore();
      const batch = db.batch();
      const studentRef = db.doc(this.student.ref.path);
      const data = {
        isLinkedToQanda: true,
        qandaUserId: res.data.res.qandaUniqueId
      };
      batch.set(studentRef, { ...data }, { merge: true });

      const externalServicesRef = studentRef
        .collection(externalServicesCollectionKey)
        .doc("qanda");
      batch.set(
        externalServicesRef,
        {
          ...data,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          description: "",
          serviceDomain: "qanda.ai/ja"
        },
        { merge: true }
      );

      await batch.commit();

      store.commit("END_LOADING");

      this.$nextTick(() => {
        this.isRegistrationDone = true;
        this.modalSize = "wide";
      });
    } catch (e) {
      console.error(e.message);
      alert("連携に失敗しました。\n" + e.message);
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  close() {
    this.$emit("qandaRegisterClose", this.isRegistrationDone);
  }
}
