
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import { Student } from "@/entities/student";
import { unitCollectionKey } from "@/entities/reflection";
import MsStudentPicker from "@/components/student/MsStudentPicker.vue";
import store from "@/store";
import "firebase/firestore";
import "firebase/functions";
import firebase from "firebase/app";

@Options({
  components: {
    MIcon,
    MBaseModal,
    MButton,
    MTextField,
    MsStudentPicker
  },
  emits: ["close"],
  props: {
    student: Object
  }
})
export default class MsEditModal extends Vue {
  student!: Student;
  selectedStudents: Student[] = [this.student];
  title = "";
  isValidTitle = false;
  enteredTitle = false;
  addingMultiple = false;
  titles: string[] = [""]; //0番目はある前提で開始

  get validTitles() {
    return this.titles.filter(_ => _).length > 0;
  }

  get validStudentLength(): boolean {
    return this.selectedStudents.length > 0;
  }

  get validData() {
    return this.validTitles && this.validStudentLength;
  }

  close() {
    this.$emit("close");
  }

  updateSelectedStudents(students: Student[]) {
    this.selectedStudents = [...students];
    console.log(this.selectedStudents);
  }

  async addUnit(
    title: string,
    studentRef: firebase.firestore.DocumentReference
  ) {
    const newRef = studentRef.collection(unitCollectionKey).doc();
    await newRef.set({
      name: title,
      createdAt: Math.floor(Date.now() / 1000),
      uid: newRef.id
    });
  }

  async commit() {
    if (!this.validData) return;
    for (const student of this.selectedStudents) {
      if (!student) {
        alert("情報が取得できない生徒が選択されています");
        return;
      }
    }
    if (this.titles.filter(_ => _).length === 0) {
      alert("追加する単元がありません。");
      return;
    }

    try {
      store.commit("START_LOADING", "単元登録中...");
      await Promise.all(
        this.titles
          .filter(_ => _)
          .map(async title =>
            Promise.all(
              this.selectedStudents.map(async (s, i) => {
                await new Promise(_ => setTimeout(_, 500 * i));
                return this.addUnit(title, s.ref);
              })
            )
          )
      );
    } catch (e) {
      alert("単元の登録に失敗しました。");
      console.error(e);
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  created(): void {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      this.$emit("close");
    }
  }
}
