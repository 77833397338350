
import { Options, Vue } from "vue-class-component";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MReservationCard from "@/components/MReservationCard.vue";
import MSPanelHeader from "@/components/student/MSPanelHeader.vue";
import {
  getStudentConfigRefFromStudentRef,
  Student,
  StudentConfig
} from "@/entities/student";
import { School, SchoolConfig } from "@/entities/school";
import { Classroom } from "@/entities/classroom";
import store, { LoginInfo } from "@/store";
import { fetchStudentConfig } from "@/api/student";
import {
  fetchReservationsOfStudent,
  fetchRegularlyReservationsOfStudent,
  deleteReservation,
  deleteRegularlyReservation
} from "@/api/reservation";
import { Reservation } from "@/entities/reservation";
import { RegularlyReservation } from "@/entities/regularly_reservation";
import dayjs from "dayjs";
import { CustomProperty } from "@/entities/custom_property";
import { saveErrorLog } from "@/api/error";
import { Getter } from "@/store/helper";
import { getSchoolIdOfStudent } from "@/api/school";
import { convertToDateFromUnixtime } from "@/utils/date";

@Options({
  components: {
    MButton,
    MIcon,
    MReservationCard,
    MSPanelHeader
  },
  emits: [
    "open",
    "openReserve",
    "openComment",
    "openAddProperty",
    "openEditProperty",
    "openTodoEditModal"
  ],
  props: {
    student: Object,
    school: Object,
    schoolConfig: Object,
    classroom: Object,
    customProperties: Array
  }
})
export default class MSProfilePanel extends Vue {
  student!: Student;
  school?: School;
  schoolConfig?: SchoolConfig;
  classroom?: Classroom;
  customProperties: CustomProperty[] = [];
  characteristic: string = this.student.data.characteristic ?? "";
  studentConfig: StudentConfig | null = null;
  reservations: Reservation[] = [];
  regularlyReservations: RegularlyReservation[] = [];
  emailReceivers: string[] = this.student.data.emailReceivers ?? [];
  externalServices: ({
    serviceName: string;
    destinationUrl: string;
    imageSrc?: string;
    description?: string;
    unlinkSchoolAIDate?: string;
    relinkSchoolAIDate?: string;
  } | null)[] = [];

  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isAdminOfSchool") isAdminOfSchool!: boolean;
  @Getter("isSupervisorOfSchool") isSupervisorOfSchool!: boolean;
  @Getter("isEmployeeOfSchool") isEmployeeOfSchool!: boolean;
  @Getter("isTutorOfSchool") isTutorOfSchool!: boolean;

  get isLinkedToLINE(): boolean {
    if (!this.studentConfig) {
      return false;
    }
    if (!this.studentConfig.data.lineId) {
      return false;
    } else {
      return true;
    }
  }

  get inRecess(): boolean {
    if (this.student.data.recessTime && this.student.data.recessTime > 0) {
      return true;
    } else {
      return false;
    }
  }

  get comment(): string {
    const secrets = store.state.studentSecrets;
    const matchSecrets = secrets.filter(
      item => item.ref.id === this.student.ref.id
    );
    if (matchSecrets.length > 0) {
      return matchSecrets[0].data.comment;
    } else {
      return "-";
    }
  }

  get loginId(): string {
    if (!this.school) {
      return "";
    }

    // ログインIDをstudetnConfigに保存していなかった頃に作られたアカウントに対応する
    return (
      this.studentConfig?.data.loginId ??
      `${this.school.data.id}-${this.student.data.id}`
    );
  }

  get classroomOfSchoolText(): string {
    if (!this.school || !this.classroom) {
      return "-";
    }

    return `${this.school.data.name} ${this.classroom.data.name}`;
  }

  get classScheduleText(): string {
    if (this.student.data.classSchedules.length === 0) {
      return "-";
    }
    return this.student.data.classSchedules.join(" / ");
  }

  get customDatas(): { title: string; value: string }[] {
    const baseProperties = this.isAdmin
      ? this.customProperties.filter(property => property.data.shareToAdmin)
      : this.customProperties;

    return baseProperties.map(property => {
      if (!this.student.data.properties) {
        return {
          title: property.data.title,
          value: "-"
        };
      }
      const matchProperties = this.student.data.properties.filter(
        item => item.id === property.ref.id
      );
      if (
        matchProperties.length === 0 ||
        matchProperties[0].value.toString().length === 0
      ) {
        return {
          title: property.data.title,
          value: "-"
        };
      }
      return {
        title: property.data.title,
        value: matchProperties[0].value.toString()
      };
    });
  }

  get canEditStudent(): boolean {
    const currentSchoolId = store.state.school?.ref.id;
    const schoolIdOfThisStudent = getSchoolIdOfStudent(this.student);

    if (!currentSchoolId || !schoolIdOfThisStudent) {
      return false;
    }

    return currentSchoolId === schoolIdOfThisStudent;
  }

  get hideLoginInvitaion(): boolean {
    if (!this.schoolConfig) {
      return true;
    }
    return this.schoolConfig.data.hideLoginInvitaion ?? false;
  }

  open() {
    this.$emit("open");
  }

  openReserve() {
    this.$emit("openReserve");
  }

  textOfReservation(reservation: Reservation): string {
    const startTime = dayjs(
      (reservation.data.notifyTime + reservation.data.beforeMinute * 60) * 1000
    ).locale("ja");
    return startTime.format("M/D HH:mm");
  }

  textOfRegularlyReservation(reservation: RegularlyReservation): string {
    const timeText = dayjs(`2021-04-01 ${reservation.data.time}`)
      .add(reservation.data.beforeMinute, "minute")
      .format("HH:mm");
    if (reservation.data.type === "month") {
      return `毎月${reservation.data.monthday}日 ${timeText}`;
    } else if (reservation.data.type === "week") {
      let weekdayText = "";
      if (reservation.data.weekday === 0) {
        weekdayText = "日";
      } else if (reservation.data.weekday === 1) {
        weekdayText = "月";
      } else if (reservation.data.weekday === 2) {
        weekdayText = "火";
      } else if (reservation.data.weekday === 3) {
        weekdayText = "水";
      } else if (reservation.data.weekday === 4) {
        weekdayText = "木";
      } else if (reservation.data.weekday === 5) {
        weekdayText = "金";
      } else if (reservation.data.weekday === 6) {
        weekdayText = "土";
      }
      return `毎週${weekdayText}曜日 ${timeText}`;
    } else {
      return `毎日 ${timeText}`;
    }
  }

  async moveToPrint(routeParam: string) {
    if (
      !this.school ||
      !(
        this.isAdminOfSchool ||
        this.isSupervisorOfSchool ||
        this.isEmployeeOfSchool
      ) ||
      !this.studentConfig
    ) {
      return;
    }
    const targetUrl = `/student/print/${routeParam}`;
    const loginInfo: LoginInfo = {
      schoolName: this.school.data.name,
      studentName: this.student.data.name,
      loginId: this.loginId,
      password: this.studentConfig.data.password
    };
    store.commit("SET_SELECTED_LOGIN_INFOS", [loginInfo]);
    this.$router.push(targetUrl);
  }

  async deleteReservation(id: string) {
    const matchReservations = this.reservations.filter(
      item => item.ref.id === id
    );
    if (matchReservations.length === 0) {
      return;
    }
    this.reservations = this.reservations.filter(item => item.ref.id !== id);
    try {
      await deleteReservation(matchReservations[0].ref);
    } catch (e) {
      alert(`予定の削除に失敗しました\n\n${e}`);
      this.reservations.push(matchReservations[0]);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete reservation"
      );
    }
  }

  async deleteRegularlyReservation(id: string) {
    const matchReservations = this.regularlyReservations.filter(
      item => item.ref.id === id
    );
    if (matchReservations.length === 0) {
      return;
    }
    this.regularlyReservations = this.regularlyReservations.filter(
      item => item.ref.id !== id
    );
    try {
      await deleteRegularlyReservation(matchReservations[0].ref);
    } catch (e) {
      alert(`予定の削除に失敗しました\n\n${e}`);
      this.regularlyReservations.push(matchReservations[0]);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete regularly reservation"
      );
    }
  }

  async created() {
    const externalServicesSnapshot = await this.student.ref
      .collection("externalServices")
      .get();
    if (externalServicesSnapshot.docs.length !== 0) {
      this.externalServices = externalServicesSnapshot.docs
        .map(doc => {
          if (doc.id === "nande" && !!this.school) {
            const serviceName = doc.id;
            const nandeUserId = doc.data().nandeUserId;
            let destinationUrl = "";
            if (window.location.hostname === "admin.mingaku.net") {
              // PROD
              destinationUrl = `https://school-ai-system.web.app/from_mingaku?school_id=${this.school.data.id}&student_id=${nandeUserId}`;
            } else if (window.location.hostname === "localhost") {
              // DEV
              destinationUrl = `http://localhost:3000/from_mingaku?school_id=${this.school.data.id}&student_id=${nandeUserId}`;
            } else {
              // STG
              destinationUrl = `https://school-ai-system-stg.web.app/from_mingaku?school_id=${this.school.data.id}&student_id=${nandeUserId}`;
            }
            const description = ""; // TODO: nandeプロジェクトのfirestoreからGETする
            const imageSrc = "shool_ai.png"; // TODO: nandeプロジェクトのstorageからGETする

            let unlinkSchoolAIDate = "";
            let relinkSchoolAIDate = "";
            if (
              "unlinkSchoolAI" in doc.data() &&
              doc.data().unlinkSchoolAI === true
            ) {
              unlinkSchoolAIDate = convertToDateFromUnixtime(
                doc.data().unlinkSchoolAIAt
              );
            }
            if (
              "unlinkSchoolAI" in doc.data() &&
              doc.data().unlinkSchoolAI === false
            ) {
              relinkSchoolAIDate = convertToDateFromUnixtime(
                doc.data().relinkSchoolAIAt
              );
            }
            return {
              serviceName,
              destinationUrl,
              imageSrc,
              description,
              unlinkSchoolAIDate,
              relinkSchoolAIDate
            };
          } else if (
            doc.id === "qanda" &&
            doc.data().isLinkedToQanda &&
            !!this.school
          ) {
            const serviceName = doc.id;
            const destinationUrl = `/qanda/${this.student.ref.id}`;
            const description = ""; // TODO: ↑のnande同様の対応？
            const imageSrc = "qanda.png";
            return { serviceName, destinationUrl, imageSrc, description };
          } else {
            return null;
          }
        })
        .filter(_ => _);
    }
    const studentConfigRef = getStudentConfigRefFromStudentRef(
      this.student.ref
    );
    try {
      const [config, reservations, regularlyReservations] = await Promise.all([
        fetchStudentConfig(studentConfigRef),
        fetchReservationsOfStudent(
          this.student.ref,
          Math.floor(Date.now() / 1000)
        ),
        fetchRegularlyReservationsOfStudent(this.student.ref)
      ]);
      if (!config) {
        alert("一部生徒情報の取得に失敗しました");
        return;
      }
      this.studentConfig = config;
      this.reservations = reservations;
      this.regularlyReservations = regularlyReservations;
      document.title = `${this.student.data.name} 生徒情報`;
    } catch (e) {
      alert(`一部生徒情報の取得に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to get student detail"
      );
    }
  }

  openCommentModal() {
    if (this.comment === "-") {
      this.$emit("openComment", "");
    } else {
      this.$emit("openComment", this.comment);
    }
  }

  openAddPropertyModal() {
    this.$emit("openAddProperty");
  }

  openEditPropertyModal(title: string) {
    const matchProperties = this.customProperties.filter(
      property => property.data.title === title
    );
    if (matchProperties.length === 0) {
      return;
    }
    this.$emit("openEditProperty", matchProperties[0].ref.id);
  }
}
