
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import MButton from "@/components/MButton.vue";
import MSPanelHeader from "@/components/student/MSPanelHeader.vue";
import { Student } from "@/entities/student";
import store from "@/store";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import { todoCollectionKey, convertToTodo, Todo } from "@/entities/todo";
import { Getter } from "@/store/helper";
import { getSchoolIdOfStudent } from "@/api/school";
import {
  getStudentsTodos,
  SuccessfulResult,
  formatTodoDataForCsv
} from "@/api/todo";
import { exportToShiftJisCsv } from "@/utils/csv";

type TodoData = {
  id: string;
  title: string;
  createdAt: string;
  checkedAt: string | null;
};

const limit = 20;

@Options({
  components: {
    MSPanelHeader,
    MIcon,
    MButton
  },
  emits: ["open", "select", "openTodoAddModal"],
  props: {
    student: Object
  }
})
export default class MSSubmissionsPanel extends Vue {
  student!: Student;
  todos: TodoData[] = [];
  sortedTodos: TodoData[] = [];
  loading = false;
  todosFinished = false;
  moreTodosLoading = false;
  isDesc = true;
  sortKey: "createdAt" | "checkedAt" = "createdAt";

  createdAtHovered = false;

  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isAdminOfSchool") isAdminOfSchool!: boolean;
  @Getter("isSupervisorOfSchool") isSupervisorOfSchool!: boolean;
  @Getter("isEmployeeOfSchool") isEmployeeOfSchool!: boolean;
  @Getter("isTutorOfSchool") isTutorOfSchool!: boolean;

  get canEditStudent(): boolean {
    const currentSchoolId = store.state.school?.ref.id;
    const schoolIdOfThisStudent = getSchoolIdOfStudent(this.student);

    if (!currentSchoolId || !schoolIdOfThisStudent) {
      return false;
    }

    return currentSchoolId === schoolIdOfThisStudent;
  }

  open() {
    this.$emit("open");
  }

  openTodoAddModal() {
    this.$emit("openTodoAddModal");
  }

  select(todo: TodoData) {
    this.$emit("select", todo);
  }

  async exportTodos() {
    let result: any;
    try {
      store.commit("START_LOADING", "TODO情報取得中...");
      result = await getStudentsTodos([this.student.ref]);
      if (result.status !== "success") throw new Error("Failed to get todos");
    } catch (e) {
      alert("TODO情報の取得に失敗しました。");
      console.error(e);
      return;
    } finally {
      store.commit("END_LOADING");
    }
    const rawData = (result.res.successfulResults as SuccessfulResult[]).sort(
      (a, b) => {
        if (a.schoolId < b.schoolId) return -1;
        if (a.schoolId > b.schoolId) return 1;
        if (a.classroomId < b.classroomId) return -1;
        if (a.classroomId > b.classroomId) return 1;
        if (a.studentId < b.studentId) return -1;
        if (a.studentId > b.studentId) return 1;
        if (a.createdAt < b.createdAt) return -1;
        if (a.createdAt > b.createdAt) return 1;
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      }
    );
    const textToExport = formatTodoDataForCsv(rawData);
    exportToShiftJisCsv(
      textToExport,
      `TODOデータ_${this.student.data.name}_${dayjs().format("YYYY-MM-DD")}`
    );
  }

  sortTodos(key: "createdAt" | "checkedAt", order: "desc" | "asc") {
    const isDesc = order === "desc";
    return [...this.todos].sort((a, b) => {
      const keyA = a[key];
      const keyB = b[key];
      if (keyA !== null && keyB !== null) {
        if (keyA < keyB) return isDesc ? 1 : -1;
        if (keyA > keyB) return isDesc ? -1 : 1;
      }
      if (keyA === null && keyB !== null) return isDesc ? -1 : 1;
      if (keyA !== null && keyB === null) return isDesc ? 1 : -1;
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    });
  }

  toggleSortKey(sortKey: "createdAt" | "checkedAt") {
    if (this.sortKey === sortKey) {
      this.isDesc = !this.isDesc;
    } else {
      this.isDesc = true;
    }
    this.sortKey = sortKey;
  }

  toggleSortOrder(sortKey: "createdAt" | "checkedAt") {
    this.toggleSortKey(sortKey);
    this.sortedTodos = this.isDesc
      ? this.sortTodos(sortKey, "desc")
      : this.sortTodos(sortKey, "asc");
  }

  async getMoreTodos() {
    this.moreTodosLoading = true;
    try {
      const id = this.todos[this.todos.length - 1].id;
      const ref = this.student.ref;
      const offsetSnapshot = await ref
        .collection(todoCollectionKey)
        .doc(id)
        .get();
      const snapshot = await ref
        .collection(todoCollectionKey)
        .orderBy("createdAt", "desc")
        .orderBy("title")
        .startAfter(offsetSnapshot)
        .limit(limit)
        .get();
      const inComingTodos = !snapshot.empty
        ? this.formatTodos(
            snapshot.docs.map(doc => convertToTodo(doc.data(), doc.ref))
          )
        : [];
      if (inComingTodos.length < limit) {
        this.todosFinished = true;
      }

      this.todos = [...this.todos, ...inComingTodos];
      this.sortedTodos = this.isDesc
        ? this.sortTodos(this.sortKey, "desc")
        : this.sortTodos(this.sortKey, "asc");
    } catch (e) {
      alert("TODOの取得に失敗しました。");
      console.error(e);
    } finally {
      this.moreTodosLoading = false;
    }
  }

  formatTodos(todos: Todo[]) {
    if (todos.length === 0) return [];
    return todos
      .filter(_ => _)
      .map(todo => {
        return {
          id: todo.ref.id,
          title: todo.data.title,
          createdAt: dayjs.unix(todo.data.createdAt).format("YYYY-MM-DD"),
          checkedAt:
            todo.data.isDoneAt !== 0
              ? dayjs.unix(todo.data.isDoneAt as number).format("YYYY-MM-DD")
              : ""
        };
      });
  }

  async created(): Promise<void> {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      return;
    }

    store.commit("START_LOADING", "TODO情報取得中...");
    this.loading = true;
    try {
      const todoSnapshot = await this.student.ref
        .collection(todoCollectionKey)
        .orderBy("createdAt", "desc")
        .orderBy("title")
        .limit(limit)
        .get();

      if (!todoSnapshot || todoSnapshot.empty) {
        this.todosFinished = true;
        return;
      }

      this.todos = this.formatTodos(
        todoSnapshot.docs.map(todo => convertToTodo(todo.data(), todo.ref))
      );

      this.sortedTodos = this.sortTodos("createdAt", "desc");

      if (this.todos.length < limit) {
        this.todosFinished = true;
      }
    } catch (e) {
      alert("TODO情報の取得に失敗しました。");
      console.error(e);
    } finally {
      store.commit("END_LOADING");
      this.loading = false;
    }
  }
}
