
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import { StudentPageType } from "@/store/modules/student";
import { Getter } from "@/store/helper";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MButton,
    MIcon
  },
  props: {
    selectPage: String
  }
})
export default class MSPanelHeader extends Vue {
  selectPage!: StudentPageType;

  @Getter("isExclusiveSchoolAiUser") isExclusiveSchoolAiUser!: boolean;

  get isProfilePage(): boolean {
    return this.selectPage === "profile";
  }

  get isHistoryPage(): boolean {
    return this.selectPage === "history" || this.selectPage === "learning";
  }

  get isBadgePage(): boolean {
    return this.selectPage === "badge";
  }

  get isSubmissionsPage(): boolean {
    return this.selectPage === "submissions";
  }

  get isTodoPage(): boolean {
    return this.selectPage === "todo";
  }

  get isReflectionPage(): boolean {
    return this.selectPage === "reflection";
  }
}
