import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-3 flex flex-col bg-white rounded-md border-2 border-gray-200" }
const _hoisted_2 = { class: "text-xs font-medium text-gray-500" }
const _hoisted_3 = { class: "w-full text-right mt-4" }
const _hoisted_4 = { class: "pl-1 text-xl font-bold" }
const _hoisted_5 = { class: "pl-1 text-sm font-medium text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createVNode("p", _hoisted_3, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.values, (value) => {
        return (_openBlock(), _createBlock(_Fragment, {
          key: value.label
        }, [
          _createVNode("span", _hoisted_4, _toDisplayString(value.num), 1),
          _createVNode("span", _hoisted_5, _toDisplayString(value.label), 1)
        ], 64))
      }), 128))
    ])
  ]))
}