
import MIcon from "@/components/MIcon.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MIcon
  },
  emits: ["delete"],
  props: {
    text: String,
    deletable: Boolean
  }
})
export default class MReservationCard extends Vue {
  text = "";
  deletable = false;

  deleteData() {
    this.$emit("delete");
  }
}
