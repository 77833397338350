
import { Options, Vue } from "vue-class-component";
import { LearningSubject } from "@/entities/learning";

@Options({
  emits: ["update"],
  props: {
    subjects: Array
  }
})
export default class MSubjectsInput extends Vue {
  subjects: LearningSubject[] = [];
  update(subject: LearningSubject) {
    this.$emit("update", subject);
  }
}
