import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "my-6 text-sm text-gray-500 text-center" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-wrap justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MBaseModal = _resolveComponent("MBaseModal")

  return (_openBlock(), _createBlock(_component_MBaseModal, {
    size: "sm",
    onClose: _ctx.close
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.badge.data.name), 1)
    ]),
    body: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("img", {
          src: _ctx.image,
          alt: "バッジ画像",
          class: "w-1/2"
        }, null, 8, ["src"]),
        _createVNode("p", _hoisted_2, _toDisplayString(_ctx.description), 1)
      ]),
      (_ctx.steps.length > 0)
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.steps, (step) => {
              return (_openBlock(), _createBlock("div", {
                key: step.label,
                class: "w-16 mx-3 mb-4 flex flex-col items-center"
              }, [
                _createVNode("img", {
                  src: step.image,
                  alt: "バッジ画像"
                }, null, 8, ["src"]),
                _createVNode("p", {
                  class: ["", step.labelClass]
                }, _toDisplayString(step.label), 3)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onClose"]))
}