
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MTextField from "@/components/form/MTextField.vue";
import MSubjectsInput from "@/components/student/MSubjectsInput.vue";
import { Student } from "@/entities/student";
import { Learning, LearningData } from "@/entities/learning";
import { LearningSubject } from "@/entities/learning";
import { Label } from "@/entities/label";
import { convertToUnixtimeFromDateAndTime } from "@/utils/date";
import { fetchAllLabels } from "@/api/label";
import { addLearning, updateLatestLearing } from "@/api/learning";
import { saveErrorLog } from "@/api/error";
import store from "@/store";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon,
    MSelectBox,
    MTextArea,
    MTextField,
    MSubjectsInput
  },
  emits: ["close"],
  props: {
    student: Object
  }
})
export default class MsAddLearningModal extends Vue {
  student!: Student;
  learning!: Learning;
  startDate = dayjs()
    .locale("ja")
    .format("YYYY-MM-DD");
  startTime = "18:00";
  endDate = dayjs()
    .locale("ja")
    .format("YYYY-MM-DD");
  endTime = "22:30";
  subjects: LearningSubject[] = [];
  labels: Label[] = [];
  selectedLabelId = "";
  goal = "目標未設定";

  get selectedLabel(): Label | null {
    return (
      this.labels.find(label => label.ref.id === this.selectedLabelId) ?? null
    );
  }

  get validDay(): boolean {
    return (
      /^\d{4}-\d{2}-\d{2}$/.test(this.startDate) ||
      /^\d{4}-\d{2}-\d{2}$/.test(this.endDate)
    );
  }

  get validTime(): boolean {
    return (
      /^\d{2}:\d{2}$/.test(this.startTime) && /^\d{2}:\d{2}$/.test(this.endTime)
    );
  }

  get validRecord(): boolean {
    return this.learningRecord.every(r => r.start < r.end);
  }

  get validData() {
    return (
      this.validDay &&
      this.validTime &&
      this.validRecord &&
      !!this.goal &&
      !!this.selectedLabel &&
      this.subjects.length !== 0
    );
  }

  get learningRecord() {
    return [
      {
        start: convertToUnixtimeFromDateAndTime(this.startDate, this.startTime),
        end: convertToUnixtimeFromDateAndTime(this.endDate, this.endTime)
      }
    ];
  }

  get learningTime() {
    return this.learningRecord.reduce(
      (pre, current) => pre + current.end - current.start,
      0
    );
  }

  async addLearningData() {
    if (!this.selectedLabel) {
      return;
    }
    const [
      ,
      schoolId,
      ,
      classroomId,
      ,
      studentId
    ] = this.student.ref.path.split("/");

    const LearningData: LearningData = {
      school: schoolId,
      classroom: classroomId,
      student: studentId,
      subjects: this.subjects,
      records: this.learningRecord,
      goal: this.goal,
      timestamp: convertToUnixtimeFromDateAndTime(
        this.startDate,
        this.startTime
      ),
      totalTime: this.learningTime,
      day: this.startDate,
      meta: {
        labels: [this.selectedLabel.ref],
        type: this.selectedLabel.data.type
      }
    };
    store.commit("START_LOADING", "学習データの追加を実行中...");
    try {
      const ref = await addLearning(this.student.ref, LearningData);
      await updateLatestLearing(ref);
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`学習データの追加に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to add learning"
      );
      return;
    }
    await store.dispatch("getStudents");
    this.$router.go(0);
  }

  changeSubjects(subjects: LearningSubject[]) {
    this.subjects = [...subjects];
  }

  close() {
    this.$emit("close");
  }

  async created() {
    this.labels = await fetchAllLabels();
  }
}
