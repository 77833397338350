
import MSPanelHeader from "@/components/student/MSPanelHeader.vue";
import SubmissionCard from "@/components/submission/SubmissionCard.vue";
import ThreadCard from "@/components/submission/ThreadCard.vue";
import { Options, Vue } from "vue-class-component";
import { Submission, ComplementedSubmission } from "@/entities/submission";
import { ComplementedThread } from "@/entities/thread";
import {
  getSubmissionOfStudent,
  complementSubmissions
} from "@/api/submission";
import { getThreadOfStudent, complementThreads } from "@/api/thread";
import { Student } from "@/entities/student";
import { putSubmission } from "@/api/submission";
import store from "@/store";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import { getSchoolDetail } from "@/api/school";
import { Getter } from "@/store/helper";
import { MessageUserType } from "@/entities/message";
import { MessageToType } from "@/entities/message_reservation";
import { sendTextMessage } from "@/api/message";
import { createMessageReservation } from "@/api/message_reservation";
import { setNotificationForSubmissionChecked } from "@/api/notification";
import { getCurrentUnixtime } from "@/utils/date";

const basicSubmissionCount = 10; //提出物の初期表示個数

@Options({
  components: {
    MSPanelHeader,
    SubmissionCard,
    ThreadCard
  },
  emits: ["open"],
  props: {
    student: Object
  }
})
export default class MSSubmissionsPanel extends Vue {
  student!: Student;
  submissions: Submission[] = [];
  complementedSubmissions: ComplementedSubmission[] = [];
  complementedThreads: ComplementedThread[] = [];
  allSubmissionsFetched = false;
  submissionLimit = basicSubmissionCount;
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isTutorOfSchool") isTutorOfSchool!: boolean;
  showPastDateForDisplayEndDate = false;

  open() {
    this.$emit("open");
  }

  changeShowDisplayEndDateFlag() {
    this.showPastDateForDisplayEndDate = !this.showPastDateForDisplayEndDate;
  }

  get submissionsForCard(): ComplementedSubmission[] {
    return this.complementedSubmissions.length < this.submissionLimit
      ? this.complementedSubmissions
      : this.complementedSubmissions.filter((_, i) => i < this.submissionLimit);
  }
  get threadsForCard(): ComplementedThread[] {
    return this.complementedThreads.length + this.submissionsForCard.length <
      this.submissionLimit
      ? this.complementedThreads
      : this.complementedThreads.filter(
          (_, i) => i < this.submissionLimit - this.submissionsForCard.length
        );
  }

  get filteredSubmissionsForCard(): ComplementedSubmission[] {
    if (this.showPastDateForDisplayEndDate) return this.submissionsForCard;
    return this.submissionsForCard.filter(s => {
      const displayEndAt = s.data.displayEndAt ?? 0;
      return displayEndAt === 0 || displayEndAt > getCurrentUnixtime();
    });
  }
  get filteredThreadsForCard(): ComplementedThread[] {
    if (this.showPastDateForDisplayEndDate) return this.threadsForCard;
    return this.threadsForCard.filter(s => {
      const displayEndAt = s.data.displayEndAt ?? 0;
      return displayEndAt === 0 || displayEndAt > getCurrentUnixtime();
    });
  }

  getMoreSubmission() {
    this.submissionLimit += basicSubmissionCount;
    if (
      this.complementedSubmissions.length + this.complementedThreads.length <=
      this.submissionLimit
    ) {
      this.allSubmissionsFetched = true;
    }
  }

  async confirm(dataMap: ComplementedSubmission) {
    store.commit("START_LOADING", "処理中");
    const now = dayjs();
    try {
      await putSubmission(dataMap.ref, {
        checkedAt: now.unix(),
        status: "fulfilled"
      });
    } catch (e) {
      alert(`提出物の更新中にエラーが発生しました。`);
      store.commit("END_LOADING");
      return;
    }

    try {
      // 塾名の取得
      const [school] = await getSchoolDetail(
        false,
        dataMap.submissionMasterRef?.parent?.parent?.id ?? ""
      );
      if (!school) throw new Error("school not found");
      const schoolName = school.data.name;
      // 差出人表記の取得
      let fromName = "";
      if (this.isTutorOfSchool) {
        fromName = store.state.tutor?.main.data.name ?? "";
      } else if (this.isAdmin) {
        fromName = store.state.admin?.name ?? "";
      } else {
        throw new Error("user not found");
      }
      const messageFrom = {
        type: "tutor" as MessageUserType,
        userId: store.state.role?.ref.id ?? "",
        name: fromName
      };
      const messageText = `🙆‍♂️「${dataMap.submissionMasterData?.name}」を確認しました。おつかれさま😄`;
      if (now.hour() >= 7 && now.hour() <= 21) {
        // 7:00 ~ 21:59 の間は即座に生徒にメッセージを送信する
        await sendTextMessage(
          dataMap.studentRef!,
          schoolName,
          messageFrom,
          messageText
        );
        alert(`提出物を確認済にし、生徒にメッセージを送信しました。`);
      } else {
        // 夜間早朝は次の 7:00 に送信されるメッセージを予約する
        const messageTo = {
          type: "students" as MessageToType,
          students: [dataMap.studentRef!]
        };
        const reservationTime = dayjs()
          .add(now.hour() > 21 ? 1 : 0, "day")
          .hour(7)
          .minute(0)
          .second(0)
          .unix();
        await createMessageReservation(
          messageFrom,
          messageTo,
          "text",
          messageText,
          reservationTime,
          school.ref.id,
          schoolName
        );
        alert(
          `提出物を確認済にしました。生徒へのメッセージは次の朝 8 時に送信されます。`
        );
      }
      // 対応する通知メッセージを既読にする
      await setNotificationForSubmissionChecked(dataMap.ref);
      // 今回の提出物のステータスを更新
      this.complementedSubmissions = this.complementedSubmissions.map(
        _dataMap => {
          if (_dataMap.ref.id !== dataMap.ref.id) return _dataMap;
          _dataMap.data.checkedAt = now.unix();
          _dataMap.data.status = "fulfilled";
          return _dataMap;
        }
      );
      this.$router.go(0);
    } catch (e) {
      alert(`メッセージ送信中にエラーが発生しました。`);
    } finally {
      store.commit("END_LOADING");
    }
  }

  async created() {
    try {
      const submissions = await getSubmissionOfStudent(this.student.ref);
      this.complementedSubmissions = await complementSubmissions(submissions);
      const threads = await getThreadOfStudent(this.student.ref);
      this.complementedThreads = await complementThreads(threads);
      if (
        this.complementedSubmissions.length + this.complementedThreads.length <=
        this.submissionLimit
      ) {
        this.allSubmissionsFetched = true;
      }
      document.title = `${this.student.data.name} 提出物`;
    } catch (e) {
      alert(`提出物の取得に失敗しました\n\n${e}`);
    }
  }
}
