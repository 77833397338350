
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MSQandaRegisterModal from "@/components/student/MSQandaRegisterModal.vue";
import { Student } from "@/entities/student";
import store from "@/store";
import "firebase/firestore";
import "firebase/functions";
import { todoCollectionKey } from "../../entities/todo";

@Options({
  components: {
    MIcon,
    MBaseModal,
    MButton,
    MSelectBox,
    MTextField,
    MTextArea,
    MSQandaRegisterModal
  },
  emits: ["close"],
  props: {
    student: Object
  }
})
export default class MsEditModal extends Vue {
  student!: Student;
  title = "";
  isValidTitle = false;
  enteredTitle = false;
  addingMultiple = false;
  titles: string[] = [""]; //0番目はある前提で開始

  get validTitles() {
    return this.titles.filter(_ => _).length > 0;
  }

  get validData() {
    return this.validTitles;
  }

  close() {
    this.$emit("close");
  }

  async addTodo() {
    if (!this.student) {
      alert("生徒情報が見つかりません。");
      return;
    }

    if (this.titles.filter(_ => _).length === 0) {
      alert("追加するTODOがありません。");
      return;
    }

    try {
      store.commit("START_LOADING", "TODO登録中...");
      await Promise.all(
        this.titles
          .filter(_ => _)
          .map(title =>
            this.student.ref.collection(todoCollectionKey).add({
              title,
              createdAt: Math.floor(Date.now() / 1000),
              isDone: false
            })
          )
      );
    } catch (e) {
      alert("TODOの登録に失敗しました。");
      console.error(e);
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  created(): void {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      this.$emit("close");
    }
  }
}
