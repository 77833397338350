
import Chart, { ChartItem } from "chart.js/auto";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    labels: Array,
    datasets: Array,
    min: {
      default: 0,
      type: Number
    },
    max: {
      default: 100,
      type: Number
    }
  },
  watch: {
    /*
    【注意】
    親コンポーネントでは、labels、datasetsの順で配列を生成してください。
    propsのdatasetsのみをwatchし、labelsは必然的に埋まっていると想定します。
    そうしないとwatchに反応したcanvasが必要以上に量産され、エラーが出ます。
    */
    datasets: function() {
      this.renderChart();
    }
  }
})
export default class MsLineChart extends Vue {
  labels: string[] = [];
  datasets = [];
  chart: Chart | null = null;
  min = 0;
  max = 100;

  renderChart() {
    if (this.labels.length === 0 || this.datasets.length === 0) return;

    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }

    const canvas = document.getElementById("chart-line");
    if (!canvas) return;

    this.chart = new Chart(canvas as ChartItem, {
      type: "line",
      data: {
        labels: this.labels,
        datasets: this.datasets
      },
      options: {
        responsive: true,
        scales: {
          xAxes: {
            stacked: true
          },
          yAxes: {
            stacked: true,
            min: this.min,
            max: this.max
          }
        }
      }
    });
  }
  mounted() {
    this.renderChart();
  }
}
