
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MTextField from "@/components/form/MTextField.vue";
import MSubjectsInput from "@/components/student/MSubjectsInput.vue";
import MSubjectsRatioInput from "@/components/student/MSubjectsRatioInput.vue";
import { Student } from "@/entities/student";
import { Learning, LearningRecord, LearningSubject } from "@/entities/learning";
import {
  updateLearning,
  deleteLearning,
  updateLatestLearing
} from "@/api/learning";
import { saveErrorLog } from "@/api/error";
import {
  convertToTimeSecFromUnixtime,
  convertToDateFromUnixtime,
  convertToUnixtimeFromDateAndTime
} from "@/utils/date";
import { Options, Vue } from "vue-class-component";
import store from "@/store";

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon,
    MSelectBox,
    MTextArea,
    MTextField,
    MSubjectsInput,
    MSubjectsRatioInput
  },
  emits: ["close"],
  props: {
    student: Object,
    learning: Object
  }
})
export default class MsEditLearningModal extends Vue {
  student!: Student;
  learning!: Learning;
  goal = "";
  subjects: LearningSubject[] = [];
  learningRecordsText: {
    startTime: string;
    endTime: string;
    startDate: string;
    endDate: string;
  }[] = [];

  get sortedRecords(): LearningRecord[] {
    if (!this.learning) {
      return [];
    }
    const timeRecords = [...this.learning.data.records];
    timeRecords.sort((a, b) => a.end - b.end);
    return timeRecords;
  }

  changeSubjects(subjects: LearningSubject[]) {
    this.subjects = [...subjects];
  }

  changeSubjectsRatio(subject: LearningSubject) {
    this.subjects = this.subjects.map(s => {
      if (s.name !== subject.name) return s;
      s.ratio = Math.round(subject.ratio);
      return s;
    });
  }

  get validDate(): boolean {
    return this.learningRecordsText.every(
      t =>
        /^\d{4}-\d{2}-\d{2}$/.test(t.startDate) &&
        /^\d{4}-\d{2}-\d{2}$/.test(t.endDate)
    );
  }
  get validTime(): boolean {
    return this.learningRecordsText.every(
      t =>
        (/^\d{2}:\d{2}:\d{2}$/.test(t.startTime) ||
          /^\d{2}:\d{2}$/.test(t.startTime)) &&
        (/^\d{2}:\d{2}:\d{2}$/.test(t.endTime) ||
          /^\d{2}:\d{2}$/.test(t.endTime))
    );
  }

  get validRecords(): boolean {
    // endよりもstartの方が大きい要素が一つでもあれば、falseを返却
    return (
      this.learningRecords.every(r => r.start < r.end) &&
      this.learningRecords.every((r, key) => {
        if (!this.learningRecords[key + 1]) return true;
        return r.end <= this.learningRecords[key + 1].start;
      })
    );
  }

  validRecord(record: {
    startTime: string;
    endTime: string;
    startDate: string;
    endDate: string;
  }): boolean {
    return (
      convertToUnixtimeFromDateAndTime(record.startDate, record.startTime) <
      convertToUnixtimeFromDateAndTime(record.endDate, record.endTime)
    );
  }

  validDatetimeConsistencyWithNextStartDatetime(
    learningRecordText: {
      startTime: string;
      endTime: string;
      startDate: string;
      endDate: string;
    },
    key: number
  ) {
    if (!this.learningRecordsText[key + 1]) return true;
    return (
      convertToUnixtimeFromDateAndTime(
        learningRecordText.endDate,
        learningRecordText.endTime
      ) <=
      convertToUnixtimeFromDateAndTime(
        this.learningRecordsText[key + 1].startDate,
        this.learningRecordsText[key + 1].startTime
      )
    );
  }

  get validData() {
    return (
      this.validDate &&
      this.validTime &&
      this.validRecords &&
      !!this.goal &&
      this.subjects.length !== 0 &&
      this.subjects.every(({ ratio }) => ratio >= 1)
    );
  }

  get learningTime() {
    return this.learningRecords.reduce(
      (pre, current) => pre + current.end - current.start,
      0
    );
  }

  get learningRecords() {
    return this.learningRecordsText.map(t => ({
      start: convertToUnixtimeFromDateAndTime(t.startDate, t.startTime),
      end: convertToUnixtimeFromDateAndTime(t.endDate, t.endTime)
    }));
  }

  async updateLearningData() {
    store.commit("START_LOADING", "変更保存中...");
    try {
      await updateLearning(
        this.learning.ref,
        {
          ...this.learning.data,
          ...{
            subjects: this.subjects,
            records: this.learningRecords,
            goal: this.goal,
            timestamp: this.learningRecords[0].start,
            totalTime: this.learningTime,
            day: convertToDateFromUnixtime(this.learningRecords[0].start)
          }
        },
        true
      );
      await updateLatestLearing(this.learning.ref);
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`学習データの編集に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update learning"
      );
      return;
    }
    await store.dispatch("getStudents");
    this.$router.go(0);
  }

  async deleteLearningData() {
    if (!this.learning) {
      return;
    }
    const confirmRes = confirm(
      "学習データを本当に削除しますか？\n⚠️削除を実行すると復元できなくなります。"
    );
    if (!confirmRes) {
      return;
    }

    store.commit("START_LOADING", "削除中...");
    try {
      await deleteLearning(this.learning.ref);
      await updateLatestLearing(this.learning.ref);
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`学習データの削除に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete learning"
      );
      return;
    } finally {
      await store.dispatch("getStudents");
      // ページのリロードが必要なので$router不使用
      window.location.href = "../history";
    }
  }

  close() {
    this.$emit("close");
  }

  created() {
    this.goal = this.learning.data.goal;
    this.subjects = this.learning.data.subjects;
    this.learningRecordsText = this.sortedRecords.map(record => ({
      startTime: convertToTimeSecFromUnixtime(record.start),
      endTime: convertToTimeSecFromUnixtime(record.end),
      startDate: convertToDateFromUnixtime(record.start),
      endDate: convertToDateFromUnixtime(record.end)
    }));
  }
}
